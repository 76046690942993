const About = () => {
  return (
    <section
      id="about"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="About me"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          About
        </h2>
      </div>
      <div>
        <p className="mb-4">
          With 3.5 years of experience in software development, I've had the
          privilege of contributing to diverse projects and collaborating with
          innovative teams. Beyond coding, I'm fueled by my passions for travel,
          photography, and literature. Exploring new cultures and capturing
          moments through my lens inspire both my work and my personal growth.
          Dive into my portfolio to discover the intersection of technology,
          creativity, and curiosity. I’ve had the privilege of building software
          for many&nbsp;
          <a
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            href="https://us.mullenlowe.com/"
            target="_blank"
            rel="noreferrer"
          >
            start-ups.
          </a>
        </p>

        <p className="mb-4">
          My main focus these days is building products and leading projects for
          our clients at&nbsp;
          <a
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            href="https://strategywerks.com/"
            target="_blank"
            rel="noreferrer"
          >
            Strategywerks
          </a>
          .
        </p>
        <p>
          When I'm not at the computer, I'm usually travelling, hanging out with
          my friends or parents, or doing some &nbsp;
          <span className="group/korok inline-flex lg:font-medium lg:text-slate-200">
            <span className="sr-only">photography</span>
            <span
              className="group-hover/korok:text-red-400 transition duration-75 group-hover/korok:-translate-y-px delay-[50ms]"
              aria-hidden="true"
            >
              p
            </span>
            <span
              className="group-hover/korok:text-orange-400 transition duration-75 group-hover/korok:-translate-y-px delay-[75ms]"
              aria-hidden="true"
            >
              h
            </span>
            <span
              className="group-hover/korok:text-yellow-400 transition duration-75 group-hover/korok:-translate-y-px delay-[100ms]"
              aria-hidden="true"
            >
              o
            </span>
            <span
              className="group-hover/korok:text-lime-400 transition duration-75 group-hover/korok:-translate-y-px delay-[125ms]"
              aria-hidden="true"
            >
              t
            </span>
            <span
              className="group-hover/korok:text-green-400 transition duration-75 group-hover/korok:-translate-y-px delay-[150ms]"
              aria-hidden="true"
            >
              o
            </span>

            <span
              className="group-hover/korok:text-cyan-400 transition duration-75 group-hover/korok:-translate-y-px delay-[175ms]"
              aria-hidden="true"
            >
              g
            </span>
            <span
              className="group-hover/korok:text-cyan-400 transition duration-75 group-hover/korok:-translate-y-px delay-[200ms]"
              aria-hidden="true"
            >
              r
            </span>
            <span
              className="group-hover/korok:text-cyan-400 transition duration-75 group-hover/korok:-translate-y-px delay-[225ms]"
              aria-hidden="true"
            >
              a
            </span>
            <span
              className="group-hover/korok:text-cyan-400 transition duration-75 group-hover/korok:-translate-y-px delay-[250ms]"
              aria-hidden="true"
            >
              p
            </span>
            <span
              className="group-hover/korok:text-cyan-400 transition duration-75 group-hover/korok:-translate-y-px delay-[275ms]"
              aria-hidden="true"
            >
              h
            </span>
            <span
              className="group-hover/korok:text-cyan-400 transition duration-75 group-hover/korok:-translate-y-px delay-[300ms]"
              aria-hidden="true"
            >
              y
            </span>
          </span>
          .
        </p>
      </div>

      <div className="mt-12">
        <a
          className="inline-flex items-center font-medium leading-tight text-slate-200 font-semibold text-slate-200 group"
          aria-label="View Full Resume"
          href="https://drive.google.com/file/d/1kGX8mVnk1YY73AaxYzRARQt3oZ7N6atm/view?usp=drive_link"
        >
          <span>
            <span className="border-b border-transparent pb-px transition group-hover:border-teal-300 motion-reduce:transition-none">
              View Full{" "}
            </span>
            <span className="whitespace-nowrap">
              <span className="border-b border-transparent pb-px transition group-hover:border-teal-300 motion-reduce:transition-none">
                Resume
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="ml-1 inline-block h-4 w-4 shrink-0 -translate-y-px transition-transform group-hover:translate-x-2 group-focus-visible:translate-x-2 motion-reduce:transition-none"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </span>
        </a>
      </div>
    </section>
  );
};
export default About;
