import MysteriousAppIcon from "../../../assets/images/mysterious-app.webp";
import StylematchApp from "../../../assets/images/stylematch.jpeg";

import ProjectCard, { ProjectCardProps } from "./ProjectCard";

const APP_PROJECTS: ProjectCardProps[] = [
  {
    name: "Mysterious Speak Easy",
    description: `A private community social media app which allow users to
    speak, other users can comment react to the post and express
    their views. It also allow users to chat in the close
    community.`,
    imageLink: MysteriousAppIcon,
    link: "https://apps.apple.com/jo/app/mysterious-speak-easy/id1632476932",
  },
  {
    name: "Stylematch",
    description: `A App for stylists to share their looks with their client and
  get in touch with them, allowing them to interact schedule
  meetins with them.`,
    imageLink: StylematchApp,
    link: "/",
  },
];
const Applications = () => {
  return (
    <section
      id="application"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="Selected applications"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          Projects
        </h2>
      </div>

      <div>
        <ul className="group/list">
          {APP_PROJECTS.map((el) => (
            <ProjectCard {...el} key={el.name} />
          ))}
        </ul>
      </div>
    </section>
  );
};
export default Applications;
