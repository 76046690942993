import AudioWaveform from "../../../assets/images/audio-waveform.png";
import ChaufferRides from "../../../assets/images/chauffer-rides.png";
import PersonalWebsite from "../../../assets/images/personal-website.png";
import TaskManagementImage from "../../../assets/images/task-management.png";
import VideoCall from "../../../assets/images/video-call.png";
import Editor from "../../../assets/images/editor.png";
import ProjectCard, { ProjectCardProps } from "./ProjectCard";
import NPM from "../../../assets/images/npm.png";

const WEB_PROJECTS: ProjectCardProps[] = [
  {
    name: "Personal Portfolio",
    description: `Portfolio Website developed using the React.js framework. This project showcases the technical prowess and creative talents of individuals in a visually stunning and interactive manner, leveraging the latest web development technologies and design principles.`,
    imageLink: PersonalWebsite,
    link: "/",
  },
  {
    name: "Video call",
    description:
      "A video call application which helps to connect with your peers seamlessly.",
    imageLink: VideoCall,
    link: "https://main.d3gd7yhe4e9iks.amplifyapp.com/",
  },
  {
    name: "Editor",
    description: "A custom editor used for creating documentation seamlessly",
    imageLink: Editor,
    link: "https://main.d3gd7yhe4e9iks.amplifyapp.com/",
  },
  {
    name: "dg-utilities",
    description:
      "A utility npm package used for for functions to use in daily life",
    imageLink: NPM,
    link: "https://www.npmjs.com/package/dg-utilities",
  },
  {
    name: "Chauffer Rides",
    description:
      "Chauffeur Rides is a state-of-the-art Chauffeur Booking Platform developed using the React.js framework. This platform serves as a centralized hub for connecting users with professional chauffeurs, offering seamless booking, scheduling, and management of chauffeur services.",
    imageLink: ChaufferRides,
    link: "https://chauffeurrideskent.co.uk/",
  },
  {
    name: "Task Management Application",
    description: `This application is a cutting-edge Task Management Application designed to streamline productivity and organization for individuals and teams alike. Leveraging the latest in web and mobile technologies, it offers a comprehensive suite of features to efficiently manage tasks, projects, and deadlines.`,
    imageLink: TaskManagementImage,
    link: "https://tasks.idineshgarg.in/",
  },
  {
    name: "WaveGenHTML",
    description: `WaveGenHTML is an innovative Audio Waveform Generator implemented using HTML5 technology. This project harnesses the power of web audio APIs and HTML canvas elements to dynamically visualize audio waveforms directly within the browser environment.`,
    imageLink: AudioWaveform,
    link: "https://audio.idineshgarg.in/",
  },
];

const Project = () => {
  return (
    <section
      id="projects"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="Selected projects"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          Projects
        </h2>
      </div>

      <div>
        <ul className="group/list">
          {WEB_PROJECTS.map((el) => (
            <ProjectCard {...el} key={el.name} />
          ))}
        </ul>
      </div>
    </section>
  );
};
export default Project;
