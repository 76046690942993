import { useLayoutEffect, useRef } from "react";
import About from "./about/About";
import Experience from "./experience/Experience";
import Applications from "./project/Applications";
import Project from "./project/Project";

const MainWrapper = () => {
  const sections = useRef<any>([]);

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection: string = "";
    const about = document.querySelectorAll(`a[href='#about']`);
    const experience = document.querySelectorAll(`a[href='#experience']`);
    const project = document.querySelectorAll(`a[href='#projects']`);
    const application = document.querySelectorAll(`a[href='#applications']`);

    sections.current.forEach((section: any) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
      }
    });

    if (!(about.length && experience.length && project.length)) {
      return;
    }

    switch (newActiveSection) {
      case "about":
        about[0].classList.add("active");
        experience[0].classList.remove("active");
        project[0].classList.remove("active");
        application[0].classList.remove("active");
        break;
      case "experience":
        experience[0].classList.add("active");
        about[0].classList.remove("active");
        project[0].classList.remove("active");
        application[0].classList.remove("active");
        break;
      case "projects":
        project[0].classList.add("active");
        about[0].classList.remove("active");
        experience[0].classList.remove("active");
        application[0].classList.remove("active");
        break;
      case "application":
        project[0].classList.remove("active");
        about[0].classList.remove("active");
        experience[0].classList.remove("active");
        application[0].classList.add("active");
        break;
      default:
    }
  };

  useLayoutEffect(() => {
    sections.current = document.querySelectorAll("section");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main id="content" className="pt-24 lg:w-1/2 lg:py-24">
      <About />
      <Experience />
      <Project />
      <Applications />
    </main>
  );
};
export default MainWrapper;
